<template>
  <a-drawer
    :width="800"
    placement="right"
    @close="closeSeeInfo"
    :closable="true"
    :destroyOnClose="true"
    :visible="seeInfoVisible"
    :drawer-style="{ position: 'absolute' }"
  >
    <a-card :bordered="false">
      <div class="header-info" style="width: 700px;">
        <a-divider>上报信息</a-divider>
        <a-spin :spinning="loading">
          <div>
            <a-descriptions :column="1" :bordered="true">
              <a-descriptions-item label="标题">
                <div style="width: 500px;">
                  {{ formData.title }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="网格员">
                <div style="width: 500px;">
                  {{ formData.realName }}({{ formData.telephone }})
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="问题分类">
                <div style="width: 500px;">
                  {{ formData.classifyTitle }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="网格卡点">
                <div style="width: 500px;">
                  {{ formData.gridName }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="状态">
                <div style="width: 500px;">
                  <a-tag color="#f50" v-if="formData.status === 0">未处理</a-tag>
                  <a-tag color="#87d068" v-else-if="formData.status === 1">已处理</a-tag>
                  <a-tag color="#595959" v-else>暂缓处理</a-tag>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="上报内容">
                <div style="width: 500px;">
                  {{ formData.content }}
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="附件">
                <div style="width: 500px;">
                  <a v-for="(item, index) in formData.attachment" :key="index" @click="handlePreview(item)" style="margin-right: 10px;">
                    <img :src="item" :height="100" />
                  </a>
                </div>
              </a-descriptions-item>
              <a-descriptions-item label="上报时间">
                <div style="width: 500px;">
                  {{ formData.createTime }}
                </div>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </a-spin>
        <a-divider>处理信息</a-divider>
        <a-spin :spinning="loadingMissonExec">
          <a-list size="large">
            <a-list-item :key="index" v-for="(item, index) in execList">
              <a-list-item-meta :description="item.content">
                <a slot="title">
                  {{ item.realName }}({{ item.telephone }})
                </a>
              </a-list-item-meta>
              <div class="list-content">
                <div class="list-content-item">
                  <span style="width: 180px;text-align: left;">附件</span>
                  <p style="width: 180px;text-align: left;">
                    <span v-for="(item, index) in item.attachment" :key="index"  style="margin-right: 10px; margin-bottom: 10px">
                      <a @click="handlePreview(item)">
                        <img :src="item" :width="60" />
                      </a>
                    </span>
                  </p>
                </div>
              </div>
              <div class="list-content">
                <div class="list-content-item">
                  <span style="min-width: 90px;text-align: left;">状态</span>
                  <p style="min-width: 90px;text-align: left;">
                    <a-tag color="#f50" v-if="item.status === 0">未处理</a-tag>
                    <a-tag color="#2db7f5" v-else-if="item.status === 1">已处理</a-tag>
                    <a-tag color="#595959" v-else>暂缓处理</a-tag>
                  </p>
                </div>
              </div>
              <div class="list-content">
                <div class="list-content-item">
                  <span style="min-width: 90px;text-align: left;">处理时间</span>
                  <p style="min-width: 90px;text-align: left;">
                    {{ item.createTime }}
                  </p>
                </div>
              </div>
            </a-list-item>
          </a-list>
        </a-spin>
      </div>
      <div>
        <p style="cursor: pointer; text-align: center;" v-if="moreData" @click="moreDataFun">点击加载更多...</p>
      </div>
      <div style="text-align: center;margin-top: 50px;">
        <a-button @click="closeSeeInfo">关闭</a-button>
      </div>
      <a-modal :visible="previewInfo.previewVisible" :title="previewInfo.previewTitle" :footer="null" @cancel="handleCancel">
        <img alt="example" style="width: 100%" :src="previewInfo.previewImage" />
      </a-modal>
    </a-card>
  </a-drawer>
</template>

<script>
import { gridEventInfo, gridEventTrackingList } from '@/api/gridevent'
export default {
  name: 'SeeEvent',
  components: {
  },
  props: {
  },
  data () {
    return {
      seeInfoVisible: false,
      loading: false,
      formData: {},
      previewInfo: {
        previewVisible: false,
        previewTitle: '',
        previewImage: ''
      },
      loadingMissonExec: false,
      execList: [],
      moreData: false,
      execParams: {
        eventId: 0,
        type: 1,
        pageNo: 1,
        pageSize: 5
      }
    }
  },
  created () {
  },
  methods: {
    closeSeeInfo () {
      this.seeInfoVisible = false
    },
    dataInfoApi (id) {
      const _this = this
      _this.seeInfoVisible = true
      _this.loading = true
      _this.execList = []
      _this.execParams.eventId = id
      _this.execParams.pageNo = 1
      _this.execListApi()
      gridEventInfo({ id: id }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.formData = res.result
          _this.loading = false
        }
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loading = false
      })
    },
    handleCancel () {
      this.previewInfo.previewVisible = false
      this.previewInfo.previewImage = ''
      this.previewInfo.previewTitle = ''
    },
    handlePreview (e) {
      this.previewInfo.previewImage = e
      this.previewInfo.previewTitle = '查看图片'
      this.previewInfo.previewVisible = true
      console.log('handlePreview', e)
    },
    execListApi () {
      const _this = this
      _this.loadingMissonExec = true
      gridEventTrackingList(_this.execParams).then((res) => {
        if (res.errorCode === 0) {
          _this.execList = _this.execList.concat(res.result.data)
          if (res.result.pageNo < res.result.totalPage) {
            _this.moreData = true
          } else {
            _this.moreData = false
          }
        } else {
          _this.$message.error('系统错误')
        }
        _this.loadingMissonExec = false
      }).catch((err) => {
        _this.$message.error('系统错误')
        console.log(err)
        _this.loadingMissonExec = false
      })
    },
    moreDataFun () {
      this.execParams.pageNo += 1
      this.execListApi()
    }
  }
}
</script>

<style lang="less" scoped>
</style>
